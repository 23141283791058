import { string, z } from 'zod'

export default {
  clinician: z.string().refine((value) => /^[a-zA-Z.,'-\s]+$/.test(value) && value.length > 2, { message: 'Invalid name.' }),

  contactMethod: string().trim().min(3),

  email: z.string({ 
    required_error: 'Email address is required.' 
  })
  .nonempty('Email address is required.')
  .email({
    message: (
      <div style={{ lineHeight: '1.5' }}>
        Invalid email. <br />
        Special characters except '+', '-', '.', and '_' are not allowed. Also, the email cannot start or end with a dot, or have consecutive dots.
      </div>
    )
  })
  .refine((value) => {
    const dotRules = /^[^.].*[^.]$/; 
    const consecutiveDotsRule = !/\.\./.test(value); 
    const specialCharRule = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);

    return dotRules.test(value) && consecutiveDotsRule && specialCharRule;
  }, {
    message: (
      <div style={{ lineHeight: '1.5' }}>
        Invalid email format. <br />
        Ensure there are no dots at the start, end, or consecutively, and only allowed special characters '+', '-', '.', and '_'.
      </div>
    )

  })
}