import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Form from "../forms/form/Form";
import styles from "./HomePage.module.scss";
import validationSchema from "../../utilities/validationschema";
import { clinicState } from "../../globalState";
import Loader from "../Loader/Loader";
import Background from "../Background/Background";
import MultiButtonForm from "./MultiButtonForm";
import SingleButtonForm from "./SingleButtonForm";
import { oldObgynClinicSlugMap } from "../../assets/data/old-obgyn-clinics";
import { oldPromoClinicSlugMap } from "../../assets/data/old-promo-clinics";
import { LunaJoyFoundationService } from "../../services/LunajoyFoundationService";
import { Modal } from "antd";
import formsApplicationUrl from "../../utilities/urls";
import { Image } from 'antd'

// Generate slug for all clinics including Old ob-gyn clinics and promo clinic
const getSlug = (clinic, typeOfScreening) => {
  let slug = oldObgynClinicSlugMap[clinic.clinicCode];
  if (slug && typeOfScreening === "obgyn") {
    return "/ob-gyn/" + slug;
  }
  slug = oldPromoClinicSlugMap[clinic.clinicCode];
  if (slug && typeOfScreening === "obgyn") {
    return "/ob-gyn/promo/" + slug;
  }
  return "/screening/" + clinic.screeningSlugs[typeOfScreening];
};

const getFormLink = (clinic, typeOfScreening) => {
  if (!clinic) {
    return `${formsApplicationUrl}/screening/${typeOfScreening}`;
  }
  const slug = getSlug(clinic, typeOfScreening);
  return formsApplicationUrl + slug;
};

const HomePage = () => {
  const { clinic } = useRecoilValue(clinicState);
  const [buttonPressed, setButtonPressed] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttons, setButtons] = useState({
    "General Assessment": { typeOfScreening: "phq9-gad7" },
  });

  const handleFormSubmit = async (values) => {
    window.rudderanalytics.page();
    setIsLoading(true);

    const clinicNameTag = clinic?.name 
    ? `${clinic.name.toLowerCase()}-drop-off`
    : "general assessment-drop-off";

    const formData = {
      email: values?.email,
      from: "partner-landing-page",
      tags: [
        "landing page",
        clinicNameTag,
        ...(buttonPressed === "General Assessment" ? ["general assessment-drop-off"] : [])
      ],
    };
  
    const contact = await LunaJoyFoundationService.findOrCreateContactWithEmail(
      formData
    );
    if (!contact?.id) {
      Modal.error({
        title: "Opps! We encountered an error!",
        content: <p>Please try again after sometime!</p>,
        okText: "OK",
        centered: true,
      });
      setIsLoading(false);
      return;
    }
    const updateFormData = {
      contact_id: contact.id,
      payload: {
      customFields: [{ 
        id: 'OUyyT8KElN9ScCWTw71p', 
        field_value: 'Drop-off Personal Information' 
      }],
      },
    };
    // Update the contact with the custom field data
    const response = await LunaJoyFoundationService.updateGHLContact(updateFormData);

    if (response) {
      console.log('Custom field updated successfully');
    } else {
      console.error('Failed to update custom field');
    }

    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('email');
    currentUrl.searchParams.delete('link');
  
    const newUrl = getFormLink(clinic, buttons[buttonPressed].typeOfScreening);
  
    const finalUrl = `${newUrl}?contactId=${contact.id}&email=${encodeURIComponent(contact.email)}&link=${encodeURIComponent(currentUrl.href)}`;
  
    setIsLoading(false);
    window.open(finalUrl, "_self", "noopener,noreferrer");
  };
  

  useEffect(() => {
    if (clinic) {
      const allButton = {};
      if (!clinic.typesOfScreen) {
        window.open('https://partner.hellolunajoy.com', "_self", "noopener,noreferrer");
      } else {
        clinic.typesOfScreen.map((screening) => {
          if (screening === "Obgyn") {
            return { title: "Pregnancy/Postpartum", typeOfScreening: "obgyn" };
          } else if (screening === "PHQ9-GAD7") {
            return {
              title: "General Assessment",
              typeOfScreening: "phq9-gad7",
            };
          }
          return null;
        })
          .forEach((button) => {
            if (button) {
              allButton[button.title] = {
                typeOfScreening: button.typeOfScreening,
              };
            }
          });
        setButtons(allButton);
      }
    }
  }, [clinic]);

  return (
    <Background clinicDetails={clinic}>
      {isLoading ? <Loader /> : null}
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <h1 className={styles.heading}>Mental Wellness Assessment</h1>
          <div className={styles.content}>
            <p className={styles.para}>
              Provide your email address to receive the result
            </p>
            <Form onSubmit={handleFormSubmit} validateSchema={validationSchema}>
              {(renderProps) => (
                <>
                  {!clinic || clinic?.typesOfScreen?.length === 1 || (clinic?.typesOfScreen?.includes("Geriatric") && clinic?.typesOfScreen?.length === 2) ? (
                    <SingleButtonForm
                      title="Take Assessment"
                      value={Object.keys(buttons)[0]}
                      onClick={setButtonPressed}
                    />
                  ) : (
                    <MultiButtonForm
                      buttons={Object.keys(buttons)}
                      onClick={setButtonPressed}
                    />
                  )}
                </>
              )}
            </Form>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.divider}></div>
            {clinic?.logo && <Image className={styles.dynamicLogo} src={`data:image/jpeg;base64,${btoa(String.fromCharCode(...new Uint8Array(clinic.logo?.data)))}`} alt={`${clinic.name} Logo`}  preview={false} />}
            <div className={styles.bottomDescription}>
              <p>This confidential and interactive tool is crafted to evaluate your mental health wellbeing.</p>
              <p>By taking this assessment, you're opting to undergo a comprehensive mental health screening and receive personalized support via text, email, and phone from Lunajoy Health. Your results may be shared with your provider to ensure collaborative care.</p>
            </div>
          </div>
        </div>
        
      </div>
    </Background>
  );
};

export default HomePage;
