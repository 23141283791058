import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import Input from '../forms/input/Input'
import styles from './HomePage.module.scss'
import validationSchema from '../../utilities/validationschema'
import { clinicState } from '../../globalState'
import Button from '../forms/Button/Button'

const MultiButtonForm = ({ buttons, onClick }) => {
  const { clinic } = useRecoilValue(clinicState)
  const [email, setEmail] = useState(''); 
  const [emailError, setEmailError] = useState(null);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    try {
      validationSchema['email'].parse(inputValue);
      setEmailError(null);
    } catch (error) {
      setEmailError(error.errors[0]?.message);
    }
  };

  const getEmailFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('email') || '';
  };

  useEffect(() => {
    const emailFromUrl = getEmailFromUrl();
    setEmail(decodeURIComponent(emailFromUrl)); 
  }, []);

  const isEmailInvalid = email.trim() === '' || !!emailError; 

  return (
    <>
      <div className={styles.fullSection}>
        <Input 
          autofocus 
          name="email" 
          type="text" 
          placeholder="Enter email" 
          schema={validationSchema['email']} 
          onChange={handleInputChange} 
          value={email} 
        />
      </div>
      <div className={styles.section}>
        {buttons.map((button) => (
          <div key={button} className={`${styles.buttonContainer} ${isEmailInvalid ? styles.disabled : ''}`}>
            <Button 
              type="submit" 
              disabled={isEmailInvalid} 
              onClick={() => !isEmailInvalid && onClick(button)} 
            >
              {button}
            </Button>
          </div>
        ))}
      </div>
    </>
  );
};

export default MultiButtonForm;
